import { useState, useCallback, createContext } from 'react';

const ProgressContext = createContext({
  isInProgress: false,
  startProgress: () => {},
  stopProgress: () => {}
});

export const ProgressContextProvider = props => {
  const [isInProgress, setIsInProgress] = useState(false);

  const startProgress = useCallback(() => {
    setIsInProgress(true);
  }, []);

  const stopProgress = useCallback(() => {
    setIsInProgress(false);
  }, []);

  return (
    <ProgressContext.Provider
      value={{
        isInProgress: isInProgress,
        startProgress: startProgress,
        stopProgress: stopProgress
      }}>
        {props.children}
    </ProgressContext.Provider>
  );
};

export default ProgressContext;