import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { UserContextProvider } from './shared/contexts/user-context';
import { ProgressContextProvider } from './shared/contexts/progress-context';

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <ProgressContextProvider>
        <App />
      </ProgressContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
