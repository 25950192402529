import { useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import Backdrop from './Backdrop';
import UserContext from '../../contexts/user-context';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = props => {
  const { message } = props;
  const { isLoggedIn, userSettings } = useContext(UserContext);
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  return ReactDOM.createPortal(
    <> 
      <Backdrop />
      <div
        data-theme={isLoggedIn ? (userSettings?.isDarkMode ? 'dark' : 'light') : (defaultDark ? 'dark' : 'light')}
        className={styles['loading-spinner']}>
        <CircularProgress size='2rem' />
        {message &&
          <div className={styles.message}>
            {message}
          </div>
        }
      </div>
    </>,
    document.getElementById('spinner-hook')
  );
};

LoadingSpinner.propTypes = {
  message: PropTypes.string
};

export default LoadingSpinner;