import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const useAxios = () => {
  const [requestStatus, setRequestStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState({
    isLoading: false,
    isShown: true,
    message: ''
  });
  const cancelTokenSources = useRef([]);
  const loadings = useRef([]);

  axios.create({ baseURL: process.env.REACT_APP_API_URL });
  
  const clearRequestStatus = () => {
    setRequestStatus(null);
  };

  useEffect(() => {
    return () => {
      cancelTokenSources.current.forEach(source => source.cancel())
    }
  }, []);

  const sendRequest = useCallback(async (
      url,
      method = 'GET',
      body = null,
      headers = {},
      loadingMessage = '',
      isLoadingShown = true,
      responseType = 'json',
      isTimeoutHandled = false
    ) => {
      const uuid = uuidv4();
      loadings.current.push({
        uuid,
        isLoading: true,
        isShown: isLoadingShown,
        message: loadingMessage
      });
      try {
        const preRequestState = loadings.current.find(x => x.isLoading && x.isShown);
        setLoadingStatus({
          isLoading: preRequestState ? preRequestState.isLoading : false,
          isShown: preRequestState ? preRequestState.isShown : true,
          message: preRequestState ? preRequestState.message : null
        });
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSources.current.push(cancelTokenSource);
        const response = await axios({
          baseURL: process.env.REACT_APP_API_URL,
          url,
          method,
          data: body,
          headers,
          cancelToken: cancelTokenSource.token,
          responseType
        });
        cancelTokenSources.current = cancelTokenSources.current.filter(source => source !== cancelTokenSource);
        loadings.current = loadings.current.filter(x => x.uuid !== uuid);
        const postRequestState = loadings.current.find(x => x.isLoading && x.isShown);
        setLoadingStatus({
          isLoading: postRequestState ? postRequestState.isLoading : false,
          isShown: postRequestState ? postRequestState.isShown : false,
          message: postRequestState ? postRequestState.message : null
        });
        if (response.data.status && response.data.status.isShown) {
          setRequestStatus({
            message: response.data.status.message,
            severity: response.data.status.severity,
            duration: response.data.status.duration,
            detailedInfo: response.data.status.detailedInfo
          });
        }
        if (responseType === 'blob' && response.data.type === 'application/json') {
          const statusFromBlob = JSON.parse(await response.data.text());
          setRequestStatus({
            message: statusFromBlob.status.message,
            severity: statusFromBlob.status.severity,
            duration: statusFromBlob.status.duration,
            detailedInfo: statusFromBlob.status.detailedInfo
          });
        }
        if (responseType === 'blob') {
          return response;
        } else {
          return response.data;
        }
      } catch (error) {
        if (error.response?.data) {
          let status;
          if (responseType === 'blob' && error.response?.data.type === 'application/json') {
            status = JSON.parse(await error.response?.data.text());
          } else {
            status = error.response?.data;
          }          
          if (status.isShown) {
            setRequestStatus({
              message: status.message,
              severity: status.severity,
              duration: null,
              detailedInfo: status.detailedInfo,
              isLogoutRequired: status.isLogoutRequired
            });
          }
        } else {
          if (error.message !== 'Network Error') {
            setRequestStatus({
              message: error.message,
              severity: 'error',
              duration: null,
              detailedInfo: []
            });
          } else {
            if (isTimeoutHandled) {
              setRequestStatus({
                message: 'Operace vyžaduje více času a je spracovávána na pozadí.',
                severity: 'warning',
                duration: null,
                detailedInfo: []
              });
            } else {
              setRequestStatus({
                message: 'Chyba spracování požadavku.',
                severity: 'error',
                duration: null,
                detailedInfo: []
              });
            }
          }
        }
        loadings.current = loadings.current.filter(x => x.uuid !== uuid);
        const postRequestState = loadings.current.find(x => x.isLoading && x.isShown);
        setLoadingStatus({
          isLoading: postRequestState ? postRequestState.isLoading : false,
          isShown: postRequestState ? postRequestState.isShown : false,
          message: postRequestState ? postRequestState.message : null
        });
        throw error;
      }
    }, []);

  return { requestStatus, loadingStatus, sendRequest, clearRequestStatus };
};